/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~animate.css/animate.min.css";


:root {
    --white: #ffffff;
    --primary: #009EDA;
    --secondary: #24bdeb;
    --bg-color: #f4f7f8;
    --bg-secondary: #07d6ff;
    --input_field_bg: #f4f7f8;
    --transparent: rgba(255, 255, 255, 0);
    --text-light: #7c7c7c;
    --text-light2: #ababab;
    --text-dark: #4d4d4d;
    --text-black: #000;
    --red_color: #ff0000;
    --yelow_color: #f2bc0f;
    --green_color: #21b624;
    --desactivado: #c0f1fb;
    ;
    ;
    background-color: white;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // display: block;
}

.class-patient {
    --min-width: 50% !important;
}

.establecimientos{
    --width: 400px;
}

.alert-wrapper {
    border-radius: 13px !important;
    height: auto;
}

.animate__animated {
    --animate-duration: 0s;

    &:nth-child(1) {
        --animate-duration: 0.15s;
    }

    &:nth-child(2) {
        --animate-duration: 0.2s;
    }

    &:nth-child(3) {
        --animate-duration: 0.25s;
    }

    &:nth-child(4) {
        --animate-duration: 0.3s;
    }

    &:nth-child(5) {
        --animate-duration: 0.35s;
    }

    &:nth-child(6) {
        --animate-duration: 0.4s;
    }

    &:nth-child(7) {
        --animate-duration: 0.45s;
    }

    &:nth-child(8) {
        --animate-duration: 0.5s;
    }

    &:nth-child(9) {
        --animate-duration: 0.55s;
    }

    &:nth-child(10) {
        --animate-duration: 0.6s;
    }

    &:nth-child(11) {
        --animate-duration: 0.65s;
    }

    &:nth-child(12) {
        --animate-duration: 0.7s;
    }

    &:nth-child(13) {
        --animate-duration: 0.5s;
    }

    &:nth-child(14) {
        --animate-duration: 0.5s;
    }

    &:nth-child(15) {
        --animate-duration: 0.5s;
    }
}

.modal-pdf {
    // --height: 70% !important;
}