// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #009EDA;
  --ion-color-primary-rgb: 0, 158, 218;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #008bc0;
  --ion-color-primary-tint: #1aa8de;


  /** primary **/
  --color-primary: #009EDA;
  --color-secondary: #3F3F3F;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // display: block;
}